import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import Navbar from '../../components/Navbar/Navbar';
import Category from '../../components/Category/Category';
import About from '../../components/About/About';
import LatestCollection from '../../components/LatestCollection/LatestCollection';
import Footer from '../../components/Footer/Footer';
import PocketBase from 'pocketbase';
import Loading from '../../components/Loading/Loading';
import VideoDisplay from './VideoDisplay';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const LandingPage = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [displayPictures, setDisplayPictures] = useState([]);
  const [staticContents, setStaticContents] = useState([]);

  const videoRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('staticContents').getFullList();
        setStaticContents(records);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('displayPictures').getFullList();
        setDisplayPictures(records);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

 

  return (
    <>
      {displayPictures && displayPictures.length > 0 ? (
        <>
          <Navbar />
          <div className="landingPage">
            {/* Video Background */}
          <VideoDisplay />

            {/* Overlay Content */}
            <h1 style={{letterSpacing:'2px'}}>THE NOBLE-MEAN.</h1>
            <p onClick={() => navigate('/products/boots')}>Discover Our Collection</p>
          </div>
          <Category displayPictures={displayPictures} />
          { staticContents && staticContents.length > 0 && 
            <About displayPictures={displayPictures} staticContents={staticContents} />

          }
          <LatestCollection />
          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default LandingPage;
