import React from 'react';
import './videodisplay.css';
import { PLACEHOLDER } from './image';

const VideoDisplay = () => {
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  const videoRef = React.useRef(null);

  return (
    <>
    {/* <div style={{backgroundColor:'#ba842d', height:'100vh', width:'100vw', position:'absolute', zIndex:'-5'}}   /> */}

    <div
  style={{
    backgroundImage: `url('${PLACEHOLDER}')`,
    backgroundColor: '#ba842d',
    filter: 'blur(5px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    zIndex: '-4',
  }}
/>
    
     <video
      ref={videoRef}
      className={`videoBackground ${videoLoaded? 'show':'hide'}`}
      autoPlay
      loop
      controls={false}
      muted={true}
      playsInline 
      onCanPlayThrough={() => setVideoLoaded(true)}
    >
      <source
        src={`/video.webm`}
        type="video/webm"
      />
      <source
        src={`/video.mp4`}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>

    </>
   
  );
};

export default VideoDisplay;